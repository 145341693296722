import React from 'react'
import SEO from '../utils/seo'
import Layout from '../utils/layout'
import TestosteroneRestoration from '../components/SciencePage/TestosteroneRestoration'
import Faq from '../components/SciencePage/Faq'
import References from '../components/SciencePage/References'
import FoodAndDrugAdministration from '../components/SciencePage/FoodAndDrugAdministration'

import styles from '../styles/science.module.sass'

const SciencePage = ({ pageContext: { data } }) => (
  <Layout data={data} heroBodyClasses={styles.heroBody}>
    <SEO {...data.seo} />
    <TestosteroneRestoration testosteroneRestoration={data.testosteroneRestoration} />
    <Faq faq={data.faq} />
    <References references={data.references} />
    <FoodAndDrugAdministration foodAndDrugAdministration={data.foodAndDrugAdministration} />
  </Layout>
)

export default SciencePage
